export const getTasks = () => {
  return [
    {
      id: 124542,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "0.010",
      task_id: 71,
      frame_stage_id: 1,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 1,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 03, 2023",
      task: {
        id: 71,
        task_name: "Identify Business Case requirements",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124543,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "0.020",
      task_id: 73,
      frame_stage_id: 1,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 1,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 08, 2021",
      task: {
        id: 73,
        task_name: "Identify Strategic Brief",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124544,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "0.030",
      task_id: 72,
      frame_stage_id: 1,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 1,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 17, 2021",
      task: {
        id: 72,
        task_name: "Identify Core Project Requirements",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124545,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "0.040",
      task_id: 28,
      frame_stage_id: 1,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 1,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 22, 2021",
      task: {
        id: 28,
        task_name:
          "Collate comments and facilitate workshops to discuss Business Case and develop Strategic Brief with Project Team members",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124546,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "0.050",
      task_id: 59,
      frame_stage_id: 1,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 1,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 27, 2021",
      task: {
        id: 59,
        task_name:
          "Discuss initial considerations for assembling the Project Team",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124547,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "0.060",
      task_id: 63,
      frame_stage_id: 1,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 1,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 06, 2021",
      task: {
        id: 63,
        task_name: "Establish Project Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124548,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "0.070",
      task_id: 29,
      frame_stage_id: 1,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 1,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 14, 2021",
      task: {
        id: 29,
        task_name: "Collate Feedback from previous projects",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124549,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "0.080",
      task_id: 54,
      frame_stage_id: 1,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 1,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 22, 2021",
      task: {
        id: 54,
        task_name: "Contribute to preparation of Strategic Brief",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124550,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "0.090",
      task_id: 35,
      frame_stage_id: 1,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 1,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 31, 2021",
      task: {
        id: 35,
        task_name: "Comment on project Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124551,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "0.100",
      task_id: 119,
      frame_stage_id: 1,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 1,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 08, 2021",
      task: {
        id: 119,
        task_name: "Provide Feedback from previous projects",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124552,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "0.110",
      task_id: 54,
      frame_stage_id: 1,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 1,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 22, 2021",
      task: {
        id: 54,
        task_name: "Contribute to preparation of Strategic Brief",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124553,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "0.120",
      task_id: 60,
      frame_stage_id: 1,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 1,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 23, 2021",
      task: {
        id: 60,
        task_name: "Discuss project with appropriate planning authority",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124554,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "0.130",
      task_id: 119,
      frame_stage_id: 1,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 1,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 08, 2021",
      task: {
        id: 119,
        task_name: "Provide Feedback from previous projects",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124555,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "0.140",
      task_id: 54,
      frame_stage_id: 1,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 1,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 22, 2021",
      task: {
        id: 54,
        task_name: "Contribute to preparation of Strategic Brief",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124556,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "0.150",
      task_id: 54,
      frame_stage_id: 1,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 1,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 22, 2021",
      task: {
        id: 54,
        task_name: "Contribute to preparation of Strategic Brief",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124557,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "0.160",
      task_id: 54,
      frame_stage_id: 1,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 1,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 22, 2021",
      task: {
        id: 54,
        task_name: "Contribute to preparation of Strategic Brief",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124558,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "0.170",
      task_id: 48,
      frame_stage_id: 1,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 1,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 05, 2021",
      task: {
        id: 48,
        task_name:
          "Contribute Cost Information to preparation of Strategic Brief",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124559,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "0.180",
      task_id: 151,
      frame_stage_id: 1,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 1,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 12, 2021",
      task: {
        id: 151,
        task_name: "Test the robustness of the Strategic Brief",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124560,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "0.190",
      task_id: 143,
      frame_stage_id: 1,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 1,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 21, 2021",
      task: {
        id: 143,
        task_name: "Review updated Project Information",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124561,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "0.200",
      task_id: 61,
      frame_stage_id: 1,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 1,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 22, 2021",
      task: {
        id: 61,
        task_name:
          "Ensure that a strategic sustainability review of client needs and potential sites has been carried out, including reuse of existing facilities, building components or materials",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124562,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "0.210",
      task_id: 147,
      frame_stage_id: 1,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 1,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 24, 2021",
      task: {
        id: 147,
        task_name: "Strategic Brief Information Exchange",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124563,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.010",
      task_id: 50,
      frame_stage_id: 2,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 17, 2021",
      task: {
        id: 50,
        task_name:
          "Contribute to development of Initial Project Brief including Project Objectives, Quality Objectives, Project Outcomes, Sustainability Aspirations, Project Budget and other parameters or constraints",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124564,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.020",
      task_id: 123,
      frame_stage_id: 2,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 27, 2021",
      task: {
        id: 123,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124565,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.030",
      task_id: 58,
      frame_stage_id: 2,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Nov 9, 2021",
      task: {
        id: 58,
        task_name:
          "Develop Initial Project Brief with project team including Project Objectives, Quality Objectives, Project Outcomes, Sustainability Aspirations, Project Budget and other parameters or constraints",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124566,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.040",
      task_id: 27,
      frame_stage_id: 2,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Nov 17, 2021",
      task: {
        id: 27,
        task_name:
          "Collate comments and facilitate workshops as required to develop Initial Project Brief",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124567,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.050",
      task_id: 110,
      frame_stage_id: 2,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Dec 2, 2021",
      task: {
        id: 110,
        task_name:
          "Prepare Project Roles Table and Contractual Tree and continue assembling and appointing project team members",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124568,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.060",
      task_id: 112,
      frame_stage_id: 2,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Dec 8, 2021",
      task: {
        id: 112,
        task_name:
          "Prepare Schedule of Services and develop Design Responsibility Matrix including Information Exchanges with Lead Designer",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124569,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.070",
      task_id: 139,
      frame_stage_id: 2,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jan 5, 2022",
      task: {
        id: 139,
        task_name: "Review Project Programme and Feasibility Studies",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124570,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.080",
      task_id: 111,
      frame_stage_id: 2,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jan 18, 2022",
      task: {
        id: 111,
        task_name: "Prepare Risk Assessments",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124571,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.090",
      task_id: 109,
      frame_stage_id: 2,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jan 23, 2022",
      task: {
        id: 109,
        task_name: "Prepare Project Execution Plan",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124572,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.100",
      task_id: 105,
      frame_stage_id: 2,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Feb 2, 2022",
      task: {
        id: 105,
        task_name: "Prepare Handover Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124573,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.110",
      task_id: 89,
      frame_stage_id: 2,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Feb 11, 2022",
      task: {
        id: 89,
        task_name: "Monitor and review progress and performance of design team",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124574,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.120",
      task_id: 123,
      frame_stage_id: 2,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 27, 2021",
      task: {
        id: 123,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124575,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.130",
      task_id: 162,
      frame_stage_id: 2,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 1, 2022",
      task: {
        id: 162,
        task_name:
          "Where required, Contribute to preparation of Initial Project Brief",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124576,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.140",
      task_id: 47,
      frame_stage_id: 2,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 9, 2022",
      task: {
        id: 47,
        task_name: "Contribute assembly of project team",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124577,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.150",
      task_id: 52,
      frame_stage_id: 2,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 25, 2021",
      task: {
        id: 52,
        task_name:
          "Contribute to preparation of Handover Strategy and Risk Assessments",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124578,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.160",
      task_id: 35,
      frame_stage_id: 2,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 31, 2021",
      task: {
        id: 35,
        task_name: "Comment on project Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124579,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.170",
      task_id: 89,
      frame_stage_id: 2,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Feb 11, 2022",
      task: {
        id: 89,
        task_name: "Monitor and review progress and performance of design team",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124580,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.180",
      task_id: 123,
      frame_stage_id: 2,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 27, 2021",
      task: {
        id: 123,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124581,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.190",
      task_id: 50,
      frame_stage_id: 2,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 17, 2021",
      task: {
        id: 50,
        task_name:
          "Contribute to development of Initial Project Brief including Project Objectives, Quality Objectives, Project Outcomes, Sustainability Aspirations, Project Budget and other parameters or constraints",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124582,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.200",
      task_id: 60,
      frame_stage_id: 2,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 23, 2021",
      task: {
        id: 60,
        task_name: "Discuss project with appropriate planning authority",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124583,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.210",
      task_id: 152,
      frame_stage_id: 2,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jan 17, 2022",
      task: {
        id: 152,
        task_name: "Undertake Feasibility Studies",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124584,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.220",
      task_id: 113,
      frame_stage_id: 2,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jan 25, 2022",
      task: {
        id: 113,
        task_name: "Prepare Site Information report",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124585,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.230",
      task_id: 123,
      frame_stage_id: 2,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 27, 2021",
      task: {
        id: 123,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124586,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.240",
      task_id: 50,
      frame_stage_id: 2,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 17, 2021",
      task: {
        id: 50,
        task_name:
          "Contribute to development of Initial Project Brief including Project Objectives, Quality Objectives, Project Outcomes, Sustainability Aspirations, Project Budget and other parameters or constraints",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124587,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.250",
      task_id: 55,
      frame_stage_id: 2,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Feb 22, 2022",
      task: {
        id: 55,
        task_name: "Contribute to Site Information report",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124588,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.260",
      task_id: 123,
      frame_stage_id: 2,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 27, 2021",
      task: {
        id: 123,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124589,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.270",
      task_id: 50,
      frame_stage_id: 2,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 17, 2021",
      task: {
        id: 50,
        task_name:
          "Contribute to development of Initial Project Brief including Project Objectives, Quality Objectives, Project Outcomes, Sustainability Aspirations, Project Budget and other parameters or constraints",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124590,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.280",
      task_id: 55,
      frame_stage_id: 2,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Feb 22, 2022",
      task: {
        id: 55,
        task_name: "Contribute to Site Information report",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124591,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.290",
      task_id: 123,
      frame_stage_id: 2,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 27, 2021",
      task: {
        id: 123,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124592,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.300",
      task_id: 50,
      frame_stage_id: 2,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 17, 2021",
      task: {
        id: 50,
        task_name:
          "Contribute to development of Initial Project Brief including Project Objectives, Quality Objectives, Project Outcomes, Sustainability Aspirations, Project Budget and other parameters or constraints",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124593,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.310",
      task_id: 55,
      frame_stage_id: 2,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Feb 22, 2022",
      task: {
        id: 55,
        task_name: "Contribute to Site Information report",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124594,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.320",
      task_id: 123,
      frame_stage_id: 2,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 27, 2021",
      task: {
        id: 123,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124595,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.330",
      task_id: 50,
      frame_stage_id: 2,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 17, 2021",
      task: {
        id: 50,
        task_name:
          "Contribute to development of Initial Project Brief including Project Objectives, Quality Objectives, Project Outcomes, Sustainability Aspirations, Project Budget and other parameters or constraints",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124596,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.340",
      task_id: 108,
      frame_stage_id: 2,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jan 19, 2022",
      task: {
        id: 108,
        task_name: "Prepare Project Budget in consultation with Client",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124597,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.350",
      task_id: 123,
      frame_stage_id: 2,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 27, 2021",
      task: {
        id: 123,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124598,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.360",
      task_id: 123,
      frame_stage_id: 2,
      agent_id: 9,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 27, 2021",
      task: {
        id: 123,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      task_pers_user: {
        id: 15,
        first_name: "Ralph",
        last_name: "Ward",
        email: "rward@siterra.uk",
        avatar: "",
      },
    },
    {
      id: 124599,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.370",
      task_id: 123,
      frame_stage_id: 2,
      agent_id: 10,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 27, 2021",
      task: {
        id: 123,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 10,
        agent_name: "Contract Administrator",
      },
      task_pers_user: {
        id: 16,
        first_name: "Robert",
        last_name: "Hunt",
        email: "rhunt@dandf.net",
        avatar: require("@/assets/temp/users/robert_hunt.png"),
      },
    },
    {
      id: 124600,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.380",
      task_id: 136,
      frame_stage_id: 2,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Feb 24, 2022",
      task: {
        id: 136,
        task_name: "Review Health and Safety strategies",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 124601,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.390",
      task_id: 123,
      frame_stage_id: 2,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 27, 2021",
      task: {
        id: 123,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 124602,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.400",
      task_id: 6,
      frame_stage_id: 2,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 14, 2022",
      task: {
        id: 6,
        task_name: "Agree Schedule of services",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124603,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.410",
      task_id: 4,
      frame_stage_id: 2,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 23, 2021",
      task: {
        id: 4,
        task_name: "Agree Design responsibility matrix",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124604,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.420",
      task_id: 7,
      frame_stage_id: 2,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Nov 3, 2021",
      task: {
        id: 7,
        task_name:
          "Agree Technology and Communication strategies and common standards",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124605,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.430",
      task_id: 46,
      frame_stage_id: 2,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Nov 21, 2021",
      task: {
        id: 46,
        task_name: "Confirm sustainability targets",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124606,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.440",
      task_id: 44,
      frame_stage_id: 2,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Dec 1, 2021",
      task: {
        id: 44,
        task_name: "Confirm environmental requirements",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124607,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.450",
      task_id: 9,
      frame_stage_id: 2,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Dec 6, 2021",
      task: {
        id: 9,
        task_name: "Carry out early stage consultations surveys or monitoring",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124608,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.460",
      task_id: 45,
      frame_stage_id: 2,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jan 3, 2021",
      task: {
        id: 45,
        task_name: "Confirm Handover strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124609,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.470",
      task_id: 146,
      frame_stage_id: 2,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jan 14, 2021",
      task: {
        id: 146,
        task_name: "Site Waste Management Plan",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124610,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.480",
      task_id: 67,
      frame_stage_id: 2,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Feb 1, 2021",
      task: {
        id: 67,
        task_name: "Exchange initial project brief",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124611,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "1.490",
      task_id: 156,
      frame_stage_id: 2,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Feb 9, 2021",
      task: {
        id: 156,
        task_name: "Undertake UK government information exchange",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124612,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.010",
      task_id: 30,
      frame_stage_id: 3,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 2, 2022",
      task: {
        id: 30,
        task_name: "Comment on Concept Design proposals",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124613,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.020",
      task_id: 144,
      frame_stage_id: 3,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 10, 2022",
      task: {
        id: 144,
        task_name: "Sign-off Concept Design and Final Project Brief",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124614,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.030",
      task_id: 36,
      frame_stage_id: 3,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 18, 2022",
      task: {
        id: 36,
        task_name: "Comment on Project Strategies as requested",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124615,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.040",
      task_id: 90,
      frame_stage_id: 3,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 27, 2022",
      task: {
        id: 90,
        task_name: "Monitor progress of Concept Design",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124616,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.050",
      task_id: 94,
      frame_stage_id: 3,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 5, 2022",
      task: {
        id: 94,
        task_name: "Prepare and issue Final Project Brief",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124617,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.060",
      task_id: 135,
      frame_stage_id: 3,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 13, 2022",
      task: {
        id: 135,
        task_name:
          "Review Handover Strategy and Risk Assessments with project team",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124618,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.070",
      task_id: 132,
      frame_stage_id: 3,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 21, 2022",
      task: {
        id: 132,
        task_name: "Review and update Project Execution Plan",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124619,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.080",
      task_id: 138,
      frame_stage_id: 3,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 30, 2022",
      task: {
        id: 138,
        task_name:
          "Review Project Programme and agree any changes with project team",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124620,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.090",
      task_id: 38,
      frame_stage_id: 3,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "May 8, 2022",
      task: {
        id: 38,
        task_name: "Comment on stage Design Programme and Cost Information",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124621,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.100",
      task_id: 89,
      frame_stage_id: 3,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Feb 11, 2022",
      task: {
        id: 89,
        task_name: "Monitor and review progress and performance of design team",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124622,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.110",
      task_id: 33,
      frame_stage_id: 3,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "May 24, 2022",
      task: {
        id: 33,
        task_name:
          "Comment on design proposals and Project Strategies from design team members",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124623,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.120",
      task_id: 117,
      frame_stage_id: 3,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 2, 2022",
      task: {
        id: 117,
        task_name: "Prepare Sustainability Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124624,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.130",
      task_id: 106,
      frame_stage_id: 3,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 10, 2022",
      task: {
        id: 106,
        task_name: "Prepare Maintenance and operational Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124625,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.140",
      task_id: 114,
      frame_stage_id: 3,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 19, 2022",
      task: {
        id: 114,
        task_name: "Prepare stage Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124626,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.150",
      task_id: 32,
      frame_stage_id: 3,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 27, 2022",
      task: {
        id: 32,
        task_name: "Comment on Cost Information",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124627,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.160",
      task_id: 89,
      frame_stage_id: 3,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Feb 11, 2022",
      task: {
        id: 89,
        task_name: "Monitor and review progress and performance of design team",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124628,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.170",
      task_id: 95,
      frame_stage_id: 3,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 13, 2022",
      task: {
        id: 95,
        task_name: "Prepare architectural Concept Design",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124629,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.180",
      task_id: 76,
      frame_stage_id: 3,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 22, 2022",
      task: {
        id: 76,
        task_name: "Liaise with planning authorities",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124630,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.190",
      task_id: 150,
      frame_stage_id: 3,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 30, 2022",
      task: {
        id: 150,
        task_name: "Submit Planning Application (stage 3 recommended)",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124631,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.200",
      task_id: 154,
      frame_stage_id: 3,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 7, 2022",
      task: {
        id: 154,
        task_name: "Undertake third party consultations",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124632,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.210",
      task_id: 8,
      frame_stage_id: 3,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 15, 2022",
      task: {
        id: 8,
        task_name:
          "Assist Lead Designer with preparation of Stage Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124633,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.220",
      task_id: 124,
      frame_stage_id: 3,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 23, 2022",
      task: {
        id: 124,
        task_name:
          "Provide information for preparation of Cost Information and Project Strategies",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124634,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.230",
      task_id: 101,
      frame_stage_id: 3,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 1, 2022",
      task: {
        id: 101,
        task_name: "Prepare Concept Design for building services design",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124635,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.240",
      task_id: 154,
      frame_stage_id: 3,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 7, 2022",
      task: {
        id: 154,
        task_name: "Undertake third party consultations",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124636,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.250",
      task_id: 8,
      frame_stage_id: 3,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 15, 2022",
      task: {
        id: 8,
        task_name:
          "Assist Lead Designer with preparation of Stage Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124637,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.260",
      task_id: 124,
      frame_stage_id: 3,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 23, 2022",
      task: {
        id: 124,
        task_name:
          "Provide information for preparation of Cost Information and Project Strategies",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124638,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.270",
      task_id: 102,
      frame_stage_id: 3,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 31, 2022",
      task: {
        id: 102,
        task_name: "Prepare Concept Design for structural design",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124639,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.280",
      task_id: 154,
      frame_stage_id: 3,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 7, 2022",
      task: {
        id: 154,
        task_name: "Undertake third party consultations",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124640,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.290",
      task_id: 8,
      frame_stage_id: 3,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 15, 2022",
      task: {
        id: 8,
        task_name:
          "Assist Lead Designer with preparation of Stage Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124641,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.300",
      task_id: 124,
      frame_stage_id: 3,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 23, 2022",
      task: {
        id: 124,
        task_name:
          "Provide information for preparation of Cost Information and Project Strategies",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124642,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.310",
      task_id: 102,
      frame_stage_id: 3,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 31, 2022",
      task: {
        id: 102,
        task_name: "Prepare Concept Design for structural design",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124643,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.320",
      task_id: 154,
      frame_stage_id: 3,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 7, 2022",
      task: {
        id: 154,
        task_name: "Undertake third party consultations",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124644,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.330",
      task_id: 8,
      frame_stage_id: 3,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 15, 2022",
      task: {
        id: 8,
        task_name:
          "Assist Lead Designer with preparation of Stage Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124645,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.340",
      task_id: 124,
      frame_stage_id: 3,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 23, 2022",
      task: {
        id: 124,
        task_name:
          "Provide information for preparation of Cost Information and Project Strategies",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124646,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.350",
      task_id: 107,
      frame_stage_id: 3,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 6, 2022",
      task: {
        id: 107,
        task_name: "Prepare preliminary Cost information",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124647,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.360",
      task_id: 8,
      frame_stage_id: 3,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 15, 2022",
      task: {
        id: 8,
        task_name:
          "Assist Lead Designer with preparation of Stage Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124648,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.370",
      task_id: 103,
      frame_stage_id: 3,
      agent_id: 9,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 22, 2022",
      task: {
        id: 103,
        task_name: "Prepare Construction Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      task_pers_user: {
        id: 15,
        first_name: "Ralph",
        last_name: "Ward",
        email: "rward@siterra.uk",
        avatar: "",
      },
    },
    {
      id: 124649,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.380",
      task_id: 57,
      frame_stage_id: 3,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 1, 2022",
      task: {
        id: 57,
        task_name: "Develop Health and Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 124650,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.390",
      task_id: 70,
      frame_stage_id: 3,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 9, 2022",
      task: {
        id: 70,
        task_name:
          "Formal sustainability pre-assessment and identification of key areas of design focus",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124651,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.400",
      task_id: 74,
      frame_stage_id: 3,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 17, 2022",
      task: {
        id: 74,
        task_name: "Initial energy assessment",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124652,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.410",
      task_id: 1,
      frame_stage_id: 3,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 26, 2022",
      task: {
        id: 1,
        task_name:
          "‘Plain English’ descriptions of internal environmental conditions and seasonal control strategies and systems",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124653,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.420",
      task_id: 62,
      frame_stage_id: 3,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 3, 2022",
      task: {
        id: 62,
        task_name: "Environmental impact check",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124654,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.430",
      task_id: 24,
      frame_stage_id: 3,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 11, 2022",
      task: {
        id: 24,
        task_name: "Climate change check",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124655,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.440",
      task_id: 78,
      frame_stage_id: 3,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 19, 2022",
      task: {
        id: 78,
        task_name: "Liaise with project Lead and Lead Designer",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124656,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.450",
      task_id: 78,
      frame_stage_id: 3,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 19, 2022",
      task: {
        id: 78,
        task_name: "Liaise with project Lead and Lead Designer",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124657,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.460",
      task_id: 78,
      frame_stage_id: 3,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 19, 2022",
      task: {
        id: 78,
        task_name: "Liaise with project Lead and Lead Designer",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124658,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.470",
      task_id: 78,
      frame_stage_id: 3,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 19, 2022",
      task: {
        id: 78,
        task_name: "Liaise with project Lead and Lead Designer",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124659,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.480",
      task_id: 78,
      frame_stage_id: 3,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 19, 2022",
      task: {
        id: 78,
        task_name: "Liaise with project Lead and Lead Designer",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124660,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.490",
      task_id: 78,
      frame_stage_id: 3,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 19, 2022",
      task: {
        id: 78,
        task_name: "Liaise with project Lead and Lead Designer",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124661,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.500",
      task_id: 78,
      frame_stage_id: 3,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 19, 2022",
      task: {
        id: 78,
        task_name: "Liaise with project Lead and Lead Designer",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124662,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.510",
      task_id: 78,
      frame_stage_id: 3,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 19, 2022",
      task: {
        id: 78,
        task_name: "Liaise with project Lead and Lead Designer",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124663,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.520",
      task_id: 78,
      frame_stage_id: 3,
      agent_id: 9,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 19, 2022",
      task: {
        id: 78,
        task_name: "Liaise with project Lead and Lead Designer",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      task_pers_user: {
        id: 15,
        first_name: "Ralph",
        last_name: "Ward",
        email: "rward@siterra.uk",
        avatar: "",
      },
    },
    {
      id: 124664,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.530",
      task_id: 78,
      frame_stage_id: 3,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 19, 2022",
      task: {
        id: 78,
        task_name: "Liaise with project Lead and Lead Designer",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 124665,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.540",
      task_id: 121,
      frame_stage_id: 3,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 27, 2022",
      task: {
        id: 121,
        task_name:
          "Provide information as set out in the Design Responsibility Matrix incorporating Information Exchanges in accordance with Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124666,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.550",
      task_id: 121,
      frame_stage_id: 3,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 27, 2022",
      task: {
        id: 121,
        task_name:
          "Provide information as set out in the Design Responsibility Matrix incorporating Information Exchanges in accordance with Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124667,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.560",
      task_id: 121,
      frame_stage_id: 3,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 27, 2022",
      task: {
        id: 121,
        task_name:
          "Provide information as set out in the Design Responsibility Matrix incorporating Information Exchanges in accordance with Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124668,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.570",
      task_id: 121,
      frame_stage_id: 3,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 27, 2022",
      task: {
        id: 121,
        task_name:
          "Provide information as set out in the Design Responsibility Matrix incorporating Information Exchanges in accordance with Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124669,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.580",
      task_id: 121,
      frame_stage_id: 3,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 27, 2022",
      task: {
        id: 121,
        task_name:
          "Provide information as set out in the Design Responsibility Matrix incorporating Information Exchanges in accordance with Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124670,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.590",
      task_id: 121,
      frame_stage_id: 3,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 27, 2022",
      task: {
        id: 121,
        task_name:
          "Provide information as set out in the Design Responsibility Matrix incorporating Information Exchanges in accordance with Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124671,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.600",
      task_id: 121,
      frame_stage_id: 3,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 27, 2022",
      task: {
        id: 121,
        task_name:
          "Provide information as set out in the Design Responsibility Matrix incorporating Information Exchanges in accordance with Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124672,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.610",
      task_id: 121,
      frame_stage_id: 3,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 27, 2022",
      task: {
        id: 121,
        task_name:
          "Provide information as set out in the Design Responsibility Matrix incorporating Information Exchanges in accordance with Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124673,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.620",
      task_id: 121,
      frame_stage_id: 3,
      agent_id: 9,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 27, 2022",
      task: {
        id: 121,
        task_name:
          "Provide information as set out in the Design Responsibility Matrix incorporating Information Exchanges in accordance with Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      task_pers_user: {
        id: 15,
        first_name: "Ralph",
        last_name: "Ward",
        email: "rward@siterra.uk",
        avatar: "",
      },
    },
    {
      id: 124674,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.630",
      task_id: 121,
      frame_stage_id: 3,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 27, 2022",
      task: {
        id: 121,
        task_name:
          "Provide information as set out in the Design Responsibility Matrix incorporating Information Exchanges in accordance with Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 124675,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.640",
      task_id: 51,
      frame_stage_id: 3,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124676,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.650",
      task_id: 51,
      frame_stage_id: 3,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124677,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.660",
      task_id: 51,
      frame_stage_id: 3,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124678,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.670",
      task_id: 51,
      frame_stage_id: 3,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124679,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.680",
      task_id: 51,
      frame_stage_id: 3,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124680,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.690",
      task_id: 51,
      frame_stage_id: 3,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124681,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.700",
      task_id: 51,
      frame_stage_id: 3,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124682,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.710",
      task_id: 51,
      frame_stage_id: 3,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124683,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.720",
      task_id: 51,
      frame_stage_id: 3,
      agent_id: 9,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      task_pers_user: {
        id: 15,
        first_name: "Ralph",
        last_name: "Ward",
        email: "rward@siterra.uk",
        avatar: "",
      },
    },
    {
      id: 124684,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.730",
      task_id: 51,
      frame_stage_id: 3,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 124685,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.740",
      task_id: 122,
      frame_stage_id: 3,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124686,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.750",
      task_id: 122,
      frame_stage_id: 3,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124687,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.760",
      task_id: 122,
      frame_stage_id: 3,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124688,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.770",
      task_id: 122,
      frame_stage_id: 3,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124689,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.780",
      task_id: 122,
      frame_stage_id: 3,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124690,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.790",
      task_id: 122,
      frame_stage_id: 3,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124691,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.800",
      task_id: 122,
      frame_stage_id: 3,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124692,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.810",
      task_id: 122,
      frame_stage_id: 3,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124693,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.820",
      task_id: 122,
      frame_stage_id: 3,
      agent_id: 9,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      task_pers_user: {
        id: 15,
        first_name: "Ralph",
        last_name: "Ward",
        email: "rward@siterra.uk",
        avatar: "",
      },
    },
    {
      id: 124694,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.830",
      task_id: 122,
      frame_stage_id: 3,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 124695,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.840",
      task_id: 49,
      frame_stage_id: 3,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 17, 2022",
      task: {
        id: 49,
        task_name: "Contribute to development of Final Project Brief",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124696,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.850",
      task_id: 49,
      frame_stage_id: 3,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 17, 2022",
      task: {
        id: 49,
        task_name: "Contribute to development of Final Project Brief",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124697,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.860",
      task_id: 49,
      frame_stage_id: 3,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 17, 2022",
      task: {
        id: 49,
        task_name: "Contribute to development of Final Project Brief",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124698,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.870",
      task_id: 49,
      frame_stage_id: 3,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 17, 2022",
      task: {
        id: 49,
        task_name: "Contribute to development of Final Project Brief",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124699,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.880",
      task_id: 49,
      frame_stage_id: 3,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 17, 2022",
      task: {
        id: 49,
        task_name: "Contribute to development of Final Project Brief",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124700,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.890",
      task_id: 49,
      frame_stage_id: 3,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 17, 2022",
      task: {
        id: 49,
        task_name: "Contribute to development of Final Project Brief",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124701,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.900",
      task_id: 49,
      frame_stage_id: 3,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 17, 2022",
      task: {
        id: 49,
        task_name: "Contribute to development of Final Project Brief",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124702,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.910",
      task_id: 49,
      frame_stage_id: 3,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 17, 2022",
      task: {
        id: 49,
        task_name: "Contribute to development of Final Project Brief",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124703,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.920",
      task_id: 49,
      frame_stage_id: 3,
      agent_id: 9,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 17, 2022",
      task: {
        id: 49,
        task_name: "Contribute to development of Final Project Brief",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      task_pers_user: {
        id: 15,
        first_name: "Ralph",
        last_name: "Ward",
        email: "rward@siterra.uk",
        avatar: "",
      },
    },
    {
      id: 124704,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.930",
      task_id: 49,
      frame_stage_id: 3,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 17, 2022",
      task: {
        id: 49,
        task_name: "Contribute to development of Final Project Brief",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 124705,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.940",
      task_id: 65,
      frame_stage_id: 3,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 9, 2022",
      task: {
        id: 65,
        task_name: "Exchange Concept Design",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124706,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "2.950",
      task_id: 156,
      frame_stage_id: 3,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Feb 9, 2021",
      task: {
        id: 156,
        task_name: "Undertake UK government information exchange",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124707,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.010",
      task_id: 34,
      frame_stage_id: 4,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 5, 2022",
      task: {
        id: 34,
        task_name: "Comment on Developed Design proposals",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124708,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.020",
      task_id: 145,
      frame_stage_id: 4,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 9, 2022",
      task: {
        id: 145,
        task_name: "Sign-off Developed Design",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124709,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.030",
      task_id: 41,
      frame_stage_id: 4,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 13, 2022",
      task: {
        id: 41,
        task_name: "Comment on updated Project Strategies as requested",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124710,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.040",
      task_id: 91,
      frame_stage_id: 4,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 17, 2022",
      task: {
        id: 91,
        task_name: "Monitor progress of Developing Design",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124711,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.050",
      task_id: 135,
      frame_stage_id: 4,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 13, 2022",
      task: {
        id: 135,
        task_name:
          "Review Handover Strategy and Risk Assessments with project team",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124712,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.060",
      task_id: 132,
      frame_stage_id: 4,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 21, 2022",
      task: {
        id: 132,
        task_name: "Review and update Project Execution Plan",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124713,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.070",
      task_id: 138,
      frame_stage_id: 4,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 30, 2022",
      task: {
        id: 138,
        task_name:
          "Review Project Programme and agree any changes with project team",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124714,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.080",
      task_id: 38,
      frame_stage_id: 4,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "May 8, 2022",
      task: {
        id: 38,
        task_name: "Comment on stage Design Programme and Cost Information",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124715,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.090",
      task_id: 82,
      frame_stage_id: 4,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 7, 2022",
      task: {
        id: 82,
        task_name: "Manage Change Control process",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124716,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.100",
      task_id: 89,
      frame_stage_id: 4,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Feb 11, 2022",
      task: {
        id: 89,
        task_name: "Monitor and review progress and performance of design team",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124717,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.110",
      task_id: 25,
      frame_stage_id: 4,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 15, 2022",
      task: {
        id: 25,
        task_name:
          "Co-ordinate and comment on design proposals and Project Strategies as they progress",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124718,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.120",
      task_id: 161,
      frame_stage_id: 4,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 19, 2022",
      task: {
        id: 161,
        task_name:
          "Update Sustainability Strategy and Maintenance and Operational Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124719,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.130",
      task_id: 115,
      frame_stage_id: 4,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 19, 2022",
      task: {
        id: 115,
        task_name:
          "Prepare stage Design Programme in conjunction with other design team members",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124720,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.140",
      task_id: 32,
      frame_stage_id: 4,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 27, 2022",
      task: {
        id: 32,
        task_name: "Comment on Cost Information",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124721,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.150",
      task_id: 89,
      frame_stage_id: 4,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Feb 11, 2022",
      task: {
        id: 89,
        task_name: "Monitor and review progress and performance of design team",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124722,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.160",
      task_id: 96,
      frame_stage_id: 4,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Nov 4, 2022",
      task: {
        id: 96,
        task_name: "Prepare architectural Developed Design",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124723,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.170",
      task_id: 76,
      frame_stage_id: 4,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 22, 2022",
      task: {
        id: 76,
        task_name: "Liaise with planning authorities",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124724,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.180",
      task_id: 149,
      frame_stage_id: 4,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Nov 12, 2022",
      task: {
        id: 149,
        task_name: "Submit Planning Application",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124725,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.190",
      task_id: 154,
      frame_stage_id: 4,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 7, 2022",
      task: {
        id: 154,
        task_name: "Undertake third party consultations",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124726,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.200",
      task_id: 8,
      frame_stage_id: 4,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 15, 2022",
      task: {
        id: 8,
        task_name:
          "Assist Lead Designer with preparation of Stage Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124727,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.210",
      task_id: 124,
      frame_stage_id: 4,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 23, 2022",
      task: {
        id: 124,
        task_name:
          "Provide information for preparation of Cost Information and Project Strategies",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124728,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.220",
      task_id: 99,
      frame_stage_id: 4,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 10, 2022",
      task: {
        id: 99,
        task_name: "Prepare building services Developed Design",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124729,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.230",
      task_id: 154,
      frame_stage_id: 4,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 7, 2022",
      task: {
        id: 154,
        task_name: "Undertake third party consultations",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124730,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.240",
      task_id: 8,
      frame_stage_id: 4,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 15, 2022",
      task: {
        id: 8,
        task_name:
          "Assist Lead Designer with preparation of Stage Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124731,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.250",
      task_id: 124,
      frame_stage_id: 4,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 23, 2022",
      task: {
        id: 124,
        task_name:
          "Provide information for preparation of Cost Information and Project Strategies",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124732,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.260",
      task_id: 104,
      frame_stage_id: 4,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 26, 2022",
      task: {
        id: 104,
        task_name:
          "Prepare coordinated and updated proposals for structural design",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124733,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.270",
      task_id: 154,
      frame_stage_id: 4,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 7, 2022",
      task: {
        id: 154,
        task_name: "Undertake third party consultations",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124734,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.280",
      task_id: 8,
      frame_stage_id: 4,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 15, 2022",
      task: {
        id: 8,
        task_name:
          "Assist Lead Designer with preparation of Stage Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124735,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.290",
      task_id: 124,
      frame_stage_id: 4,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 23, 2022",
      task: {
        id: 124,
        task_name:
          "Provide information for preparation of Cost Information and Project Strategies",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124736,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.300",
      task_id: 104,
      frame_stage_id: 4,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 26, 2022",
      task: {
        id: 104,
        task_name:
          "Prepare coordinated and updated proposals for structural design",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124737,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.310",
      task_id: 154,
      frame_stage_id: 4,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 7, 2022",
      task: {
        id: 154,
        task_name: "Undertake third party consultations",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124738,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.320",
      task_id: 8,
      frame_stage_id: 4,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 15, 2022",
      task: {
        id: 8,
        task_name:
          "Assist Lead Designer with preparation of Stage Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124739,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.330",
      task_id: 124,
      frame_stage_id: 4,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 23, 2022",
      task: {
        id: 124,
        task_name:
          "Provide information for preparation of Cost Information and Project Strategies",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124740,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.340",
      task_id: 160,
      frame_stage_id: 4,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Nov 1, 2022",
      task: {
        id: 160,
        task_name: "Update preliminary Cost information",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124741,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.350",
      task_id: 8,
      frame_stage_id: 4,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 15, 2022",
      task: {
        id: 8,
        task_name:
          "Assist Lead Designer with preparation of Stage Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124742,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.360",
      task_id: 158,
      frame_stage_id: 4,
      agent_id: 9,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Nov 9, 2022",
      task: {
        id: 158,
        task_name: "Update Construction Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      task_pers_user: {
        id: 15,
        first_name: "Ralph",
        last_name: "Ward",
        email: "rward@siterra.uk",
        avatar: "",
      },
    },
    {
      id: 124743,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.370",
      task_id: 20,
      frame_stage_id: 4,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Nov 13, 2022",
      task: {
        id: 20,
        task_name: "Check sustainability assessment",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124744,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.380",
      task_id: 75,
      frame_stage_id: 4,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Nov 17, 2022",
      task: {
        id: 75,
        task_name: "Interim energy assessment",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124745,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.390",
      task_id: 127,
      frame_stage_id: 4,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Nov 21, 2022",
      task: {
        id: 127,
        task_name: "Resource and Waste minimisation design review",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124746,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.400",
      task_id: 159,
      frame_stage_id: 4,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 7, 2022",
      task: {
        id: 159,
        task_name: "Update Health and Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 124747,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.410",
      task_id: 51,
      frame_stage_id: 4,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124748,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.420",
      task_id: 51,
      frame_stage_id: 4,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124749,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.430",
      task_id: 51,
      frame_stage_id: 4,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124750,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.440",
      task_id: 51,
      frame_stage_id: 4,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124751,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.450",
      task_id: 51,
      frame_stage_id: 4,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124752,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.460",
      task_id: 51,
      frame_stage_id: 4,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124753,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.470",
      task_id: 51,
      frame_stage_id: 4,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124754,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.480",
      task_id: 51,
      frame_stage_id: 4,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124755,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.490",
      task_id: 51,
      frame_stage_id: 4,
      agent_id: 9,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      task_pers_user: {
        id: 15,
        first_name: "Ralph",
        last_name: "Ward",
        email: "rward@siterra.uk",
        avatar: "",
      },
    },
    {
      id: 124756,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.500",
      task_id: 51,
      frame_stage_id: 4,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 124757,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.510",
      task_id: 51,
      frame_stage_id: 4,
      agent_id: 12,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 12,
        agent_name: "Landscape Architect",
      },
      task_pers_user: {
        id: 18,
        first_name: "Elizabeth",
        last_name: "Fox",
        email: "efox@gradient.net",
        avatar: "",
      },
    },
    {
      id: 124758,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.520",
      task_id: 51,
      frame_stage_id: 4,
      agent_id: 13,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 13,
        agent_name: "Lift Engineer",
      },
      task_pers_user: {
        id: 19,
        first_name: "Harris",
        last_name: "Marshall",
        email: "hmarshall@niteroica.uk",
        avatar: require("@/assets/temp/users/harris_marshall.png"),
      },
    },
    {
      id: 124759,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.530",
      task_id: 123,
      frame_stage_id: 4,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 27, 2021",
      task: {
        id: 123,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124760,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.540",
      task_id: 123,
      frame_stage_id: 4,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 27, 2021",
      task: {
        id: 123,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124761,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.550",
      task_id: 123,
      frame_stage_id: 4,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 27, 2021",
      task: {
        id: 123,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124762,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.560",
      task_id: 123,
      frame_stage_id: 4,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 27, 2021",
      task: {
        id: 123,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124763,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.570",
      task_id: 123,
      frame_stage_id: 4,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 27, 2021",
      task: {
        id: 123,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124764,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.580",
      task_id: 123,
      frame_stage_id: 4,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 27, 2021",
      task: {
        id: 123,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124765,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.590",
      task_id: 123,
      frame_stage_id: 4,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 27, 2021",
      task: {
        id: 123,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124766,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.600",
      task_id: 123,
      frame_stage_id: 4,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 27, 2021",
      task: {
        id: 123,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124767,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.610",
      task_id: 123,
      frame_stage_id: 4,
      agent_id: 9,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 27, 2021",
      task: {
        id: 123,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      task_pers_user: {
        id: 15,
        first_name: "Ralph",
        last_name: "Ward",
        email: "rward@siterra.uk",
        avatar: "",
      },
    },
    {
      id: 124768,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.620",
      task_id: 123,
      frame_stage_id: 4,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 27, 2021",
      task: {
        id: 123,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 124769,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.630",
      task_id: 123,
      frame_stage_id: 4,
      agent_id: 12,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 27, 2021",
      task: {
        id: 123,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 12,
        agent_name: "Landscape Architect",
      },
      task_pers_user: {
        id: 18,
        first_name: "Elizabeth",
        last_name: "Fox",
        email: "efox@gradient.net",
        avatar: "",
      },
    },
    {
      id: 124770,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.640",
      task_id: 123,
      frame_stage_id: 4,
      agent_id: 13,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 27, 2021",
      task: {
        id: 123,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 13,
        agent_name: "Lift Engineer",
      },
      task_pers_user: {
        id: 19,
        first_name: "Harris",
        last_name: "Marshall",
        email: "hmarshall@niteroica.uk",
        avatar: require("@/assets/temp/users/harris_marshall.png"),
      },
    },
    {
      id: 124771,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.650",
      task_id: 79,
      frame_stage_id: 4,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 28, 2022",
      task: {
        id: 79,
        task_name: "Liaise with project Lead and lead designer as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124772,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.660",
      task_id: 79,
      frame_stage_id: 4,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 28, 2022",
      task: {
        id: 79,
        task_name: "Liaise with project Lead and lead designer as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124773,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.670",
      task_id: 79,
      frame_stage_id: 4,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 28, 2022",
      task: {
        id: 79,
        task_name: "Liaise with project Lead and lead designer as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124774,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.680",
      task_id: 79,
      frame_stage_id: 4,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 28, 2022",
      task: {
        id: 79,
        task_name: "Liaise with project Lead and lead designer as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124775,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.690",
      task_id: 79,
      frame_stage_id: 4,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 28, 2022",
      task: {
        id: 79,
        task_name: "Liaise with project Lead and lead designer as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124776,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.700",
      task_id: 79,
      frame_stage_id: 4,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 28, 2022",
      task: {
        id: 79,
        task_name: "Liaise with project Lead and lead designer as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124777,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.710",
      task_id: 79,
      frame_stage_id: 4,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 28, 2022",
      task: {
        id: 79,
        task_name: "Liaise with project Lead and lead designer as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124778,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.720",
      task_id: 79,
      frame_stage_id: 4,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 28, 2022",
      task: {
        id: 79,
        task_name: "Liaise with project Lead and lead designer as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124779,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.730",
      task_id: 79,
      frame_stage_id: 4,
      agent_id: 9,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 28, 2022",
      task: {
        id: 79,
        task_name: "Liaise with project Lead and lead designer as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      task_pers_user: {
        id: 15,
        first_name: "Ralph",
        last_name: "Ward",
        email: "rward@siterra.uk",
        avatar: "",
      },
    },
    {
      id: 124780,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.740",
      task_id: 79,
      frame_stage_id: 4,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 28, 2022",
      task: {
        id: 79,
        task_name: "Liaise with project Lead and lead designer as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 124781,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.750",
      task_id: 79,
      frame_stage_id: 4,
      agent_id: 12,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 28, 2022",
      task: {
        id: 79,
        task_name: "Liaise with project Lead and lead designer as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 12,
        agent_name: "Landscape Architect",
      },
      task_pers_user: {
        id: 18,
        first_name: "Elizabeth",
        last_name: "Fox",
        email: "efox@gradient.net",
        avatar: "",
      },
    },
    {
      id: 124782,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.760",
      task_id: 79,
      frame_stage_id: 4,
      agent_id: 13,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 28, 2022",
      task: {
        id: 79,
        task_name: "Liaise with project Lead and lead designer as required",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 13,
        agent_name: "Lift Engineer",
      },
      task_pers_user: {
        id: 19,
        first_name: "Harris",
        last_name: "Marshall",
        email: "hmarshall@niteroica.uk",
        avatar: require("@/assets/temp/users/harris_marshall.png"),
      },
    },
    {
      id: 124783,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.770",
      task_id: 121,
      frame_stage_id: 4,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 27, 2022",
      task: {
        id: 121,
        task_name:
          "Provide information as set out in the Design Responsibility Matrix incorporating Information Exchanges in accordance with Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124784,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.780",
      task_id: 121,
      frame_stage_id: 4,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 27, 2022",
      task: {
        id: 121,
        task_name:
          "Provide information as set out in the Design Responsibility Matrix incorporating Information Exchanges in accordance with Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124785,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.790",
      task_id: 121,
      frame_stage_id: 4,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 27, 2022",
      task: {
        id: 121,
        task_name:
          "Provide information as set out in the Design Responsibility Matrix incorporating Information Exchanges in accordance with Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124786,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.800",
      task_id: 121,
      frame_stage_id: 4,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 27, 2022",
      task: {
        id: 121,
        task_name:
          "Provide information as set out in the Design Responsibility Matrix incorporating Information Exchanges in accordance with Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124787,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.810",
      task_id: 121,
      frame_stage_id: 4,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 27, 2022",
      task: {
        id: 121,
        task_name:
          "Provide information as set out in the Design Responsibility Matrix incorporating Information Exchanges in accordance with Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124788,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.820",
      task_id: 121,
      frame_stage_id: 4,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 27, 2022",
      task: {
        id: 121,
        task_name:
          "Provide information as set out in the Design Responsibility Matrix incorporating Information Exchanges in accordance with Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124789,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.830",
      task_id: 121,
      frame_stage_id: 4,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 27, 2022",
      task: {
        id: 121,
        task_name:
          "Provide information as set out in the Design Responsibility Matrix incorporating Information Exchanges in accordance with Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124790,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.840",
      task_id: 121,
      frame_stage_id: 4,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 27, 2022",
      task: {
        id: 121,
        task_name:
          "Provide information as set out in the Design Responsibility Matrix incorporating Information Exchanges in accordance with Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124791,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.850",
      task_id: 121,
      frame_stage_id: 4,
      agent_id: 9,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 27, 2022",
      task: {
        id: 121,
        task_name:
          "Provide information as set out in the Design Responsibility Matrix incorporating Information Exchanges in accordance with Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      task_pers_user: {
        id: 15,
        first_name: "Ralph",
        last_name: "Ward",
        email: "rward@siterra.uk",
        avatar: "",
      },
    },
    {
      id: 124792,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.860",
      task_id: 121,
      frame_stage_id: 4,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 27, 2022",
      task: {
        id: 121,
        task_name:
          "Provide information as set out in the Design Responsibility Matrix incorporating Information Exchanges in accordance with Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 124793,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.870",
      task_id: 121,
      frame_stage_id: 4,
      agent_id: 12,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 27, 2022",
      task: {
        id: 121,
        task_name:
          "Provide information as set out in the Design Responsibility Matrix incorporating Information Exchanges in accordance with Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 12,
        agent_name: "Landscape Architect",
      },
      task_pers_user: {
        id: 18,
        first_name: "Elizabeth",
        last_name: "Fox",
        email: "efox@gradient.net",
        avatar: "",
      },
    },
    {
      id: 124794,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.880",
      task_id: 121,
      frame_stage_id: 4,
      agent_id: 13,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 27, 2022",
      task: {
        id: 121,
        task_name:
          "Provide information as set out in the Design Responsibility Matrix incorporating Information Exchanges in accordance with Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 13,
        agent_name: "Lift Engineer",
      },
      task_pers_user: {
        id: 19,
        first_name: "Harris",
        last_name: "Marshall",
        email: "hmarshall@niteroica.uk",
        avatar: require("@/assets/temp/users/harris_marshall.png"),
      },
    },
    {
      id: 124795,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.890",
      task_id: 66,
      frame_stage_id: 4,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 11, 2022",
      task: {
        id: 66,
        task_name: "Exchange Developed Design",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124796,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "3.900",
      task_id: 154,
      frame_stage_id: 4,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 7, 2022",
      task: {
        id: 154,
        task_name: "Undertake third party consultations",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124797,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.010",
      task_id: 29,
      frame_stage_id: 5,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 14, 2021",
      task: {
        id: 29,
        task_name: "Collate Feedback from previous projects",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124798,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.020",
      task_id: 40,
      frame_stage_id: 5,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Dec 5, 2022",
      task: {
        id: 40,
        task_name: "Comment on updated Project Strategies",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124799,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.030",
      task_id: 91,
      frame_stage_id: 5,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 8, 2023",
      task: {
        id: 91,
        task_name: "Monitor progress of Developing Design",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124800,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.040",
      task_id: 142,
      frame_stage_id: 5,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 17, 2023",
      task: {
        id: 142,
        task_name:
          "Review updated Handover Strategy, Project Strategies and Risk Assessments with project team",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124801,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.050",
      task_id: 132,
      frame_stage_id: 5,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 21, 2022",
      task: {
        id: 132,
        task_name: "Review and update Project Execution Plan",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124802,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.060",
      task_id: 37,
      frame_stage_id: 5,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Dec 22, 2022",
      task: {
        id: 37,
        task_name: "Comment on stage Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124803,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.070",
      task_id: 82,
      frame_stage_id: 5,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 7, 2022",
      task: {
        id: 82,
        task_name: "Manage Change Control process",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124804,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.080",
      task_id: 89,
      frame_stage_id: 5,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Feb 11, 2022",
      task: {
        id: 89,
        task_name: "Monitor and review progress and performance of design team",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124805,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.090",
      task_id: 140,
      frame_stage_id: 5,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "May 14, 2023",
      task: {
        id: 140,
        task_name:
          "Review Technical Design proposals and Project Strategies as they progress and integrate the design work specialist subcontractors",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124806,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.100",
      task_id: 161,
      frame_stage_id: 5,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 19, 2022",
      task: {
        id: 161,
        task_name:
          "Update Sustainability Strategy and Maintenance and Operational Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124807,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.110",
      task_id: 115,
      frame_stage_id: 5,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 19, 2022",
      task: {
        id: 115,
        task_name:
          "Prepare stage Design Programme in conjunction with other design team members",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124808,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.120",
      task_id: 89,
      frame_stage_id: 5,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Feb 11, 2022",
      task: {
        id: 89,
        task_name: "Monitor and review progress and performance of design team",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124809,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.130",
      task_id: 80,
      frame_stage_id: 5,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 15, 2023",
      task: {
        id: 80,
        task_name: "Liaise with specialist subcontractors",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124810,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.140",
      task_id: 97,
      frame_stage_id: 5,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Feb 8, 2023",
      task: {
        id: 97,
        task_name: "Prepare architectural Technical Design",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124811,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.150",
      task_id: 148,
      frame_stage_id: 5,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Nov 19, 2022",
      task: {
        id: 148,
        task_name:
          "Submit Building Regulations Submission (Building Warrant in Scotland)",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124812,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.160",
      task_id: 154,
      frame_stage_id: 5,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 7, 2022",
      task: {
        id: 154,
        task_name: "Undertake third party consultations",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124813,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.170",
      task_id: 8,
      frame_stage_id: 5,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 15, 2022",
      task: {
        id: 8,
        task_name:
          "Assist Lead Designer with preparation of Stage Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124814,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.180",
      task_id: 126,
      frame_stage_id: 5,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 30, 2023",
      task: {
        id: 126,
        task_name: "Provide information for update of Project Strategies",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124815,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.190",
      task_id: 80,
      frame_stage_id: 5,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 15, 2023",
      task: {
        id: 80,
        task_name: "Liaise with specialist subcontractors",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124816,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.200",
      task_id: 100,
      frame_stage_id: 5,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 19, 2023",
      task: {
        id: 100,
        task_name: "Prepare building services Technical Design",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124817,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.210",
      task_id: 155,
      frame_stage_id: 5,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 9, 2023",
      task: {
        id: 155,
        task_name:
          "Undertake third party consultations as required and any Research and Development aspects",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124818,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.220",
      task_id: 8,
      frame_stage_id: 5,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 15, 2022",
      task: {
        id: 8,
        task_name:
          "Assist Lead Designer with preparation of Stage Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124819,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.230",
      task_id: 125,
      frame_stage_id: 5,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 24, 2023",
      task: {
        id: 125,
        task_name:
          "Provide information for update of Cost Information and Project Strategies",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124820,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.240",
      task_id: 80,
      frame_stage_id: 5,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 15, 2023",
      task: {
        id: 80,
        task_name: "Liaise with specialist subcontractors",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124821,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.250",
      task_id: 92,
      frame_stage_id: 5,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 7, 2023",
      task: {
        id: 92,
        task_name: "Prepare Civil Engineering Technical Design",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124822,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.260",
      task_id: 154,
      frame_stage_id: 5,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 7, 2022",
      task: {
        id: 154,
        task_name: "Undertake third party consultations",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124823,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.270",
      task_id: 8,
      frame_stage_id: 5,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 15, 2022",
      task: {
        id: 8,
        task_name:
          "Assist Lead Designer with preparation of Stage Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124824,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.280",
      task_id: 125,
      frame_stage_id: 5,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 24, 2023",
      task: {
        id: 125,
        task_name:
          "Provide information for update of Cost Information and Project Strategies",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124825,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.290",
      task_id: 81,
      frame_stage_id: 5,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 25, 2023",
      task: {
        id: 81,
        task_name: "Liaise with specialist subcontractors as necessary",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124826,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.300",
      task_id: 116,
      frame_stage_id: 5,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Dec 1, 2022",
      task: {
        id: 116,
        task_name: "Prepare Structural Technical Design",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124827,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.310",
      task_id: 154,
      frame_stage_id: 5,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 7, 2022",
      task: {
        id: 154,
        task_name: "Undertake third party consultations",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124828,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.320",
      task_id: 8,
      frame_stage_id: 5,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 15, 2022",
      task: {
        id: 8,
        task_name:
          "Assist Lead Designer with preparation of Stage Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124829,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.330",
      task_id: 125,
      frame_stage_id: 5,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 24, 2023",
      task: {
        id: 125,
        task_name:
          "Provide information for update of Cost Information and Project Strategies",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124830,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.340",
      task_id: 81,
      frame_stage_id: 5,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 25, 2023",
      task: {
        id: 81,
        task_name: "Liaise with specialist subcontractors as necessary",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124831,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.350",
      task_id: 160,
      frame_stage_id: 5,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Nov 1, 2022",
      task: {
        id: 160,
        task_name: "Update preliminary Cost information",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124832,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.360",
      task_id: 8,
      frame_stage_id: 5,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 15, 2022",
      task: {
        id: 8,
        task_name:
          "Assist Lead Designer with preparation of Stage Design Programme",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124833,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.370",
      task_id: 158,
      frame_stage_id: 5,
      agent_id: 9,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Nov 9, 2022",
      task: {
        id: 158,
        task_name: "Update Construction Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      task_pers_user: {
        id: 15,
        first_name: "Ralph",
        last_name: "Ward",
        email: "rward@siterra.uk",
        avatar: "",
      },
    },
    {
      id: 124834,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.380",
      task_id: 98,
      frame_stage_id: 5,
      agent_id: 10,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 15, 2023",
      task: {
        id: 98,
        task_name:
          "Prepare Building Contract, agree with contractor and arrange completion",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 10,
        agent_name: "Contract Administrator",
      },
      task_pers_user: {
        id: 16,
        first_name: "Robert",
        last_name: "Hunt",
        email: "rhunt@dandf.net",
        avatar: require("@/assets/temp/users/robert_hunt.png"),
      },
    },
    {
      id: 124835,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.390",
      task_id: 159,
      frame_stage_id: 5,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 7, 2022",
      task: {
        id: 159,
        task_name: "Update Health and Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 124836,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.400",
      task_id: 20,
      frame_stage_id: 5,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Nov 13, 2022",
      task: {
        id: 20,
        task_name: "Check sustainability assessment",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124837,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.410",
      task_id: 11,
      frame_stage_id: 5,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jan 4, 2023",
      task: {
        id: 11,
        task_name: "Check airtightness and continuity of insulation",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124838,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.420",
      task_id: 15,
      frame_stage_id: 5,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 28, 2023",
      task: {
        id: 15,
        task_name: "Check energy assessment",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124839,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.430",
      task_id: 23,
      frame_stage_id: 5,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 6, 2022",
      task: {
        id: 23,
        task_name: "Check user guide",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124840,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.440",
      task_id: 18,
      frame_stage_id: 5,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 24, 2023",
      task: {
        id: 18,
        task_name: "Check outstanding design stage information",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124841,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.450",
      task_id: 16,
      frame_stage_id: 5,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 6, 2023",
      task: {
        id: 16,
        task_name: "Check monitoring technologies",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124842,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.460",
      task_id: 19,
      frame_stage_id: 5,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Sep 21, 2023",
      task: {
        id: 19,
        task_name: "Check review of changes",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124843,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.470",
      task_id: 20,
      frame_stage_id: 5,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Nov 13, 2022",
      task: {
        id: 20,
        task_name: "Check sustainability assessment",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124844,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.480",
      task_id: 51,
      frame_stage_id: 5,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124845,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.490",
      task_id: 51,
      frame_stage_id: 5,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124846,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.500",
      task_id: 51,
      frame_stage_id: 5,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124847,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.510",
      task_id: 51,
      frame_stage_id: 5,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124848,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.520",
      task_id: 51,
      frame_stage_id: 5,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124849,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.530",
      task_id: 51,
      frame_stage_id: 5,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124850,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.540",
      task_id: 51,
      frame_stage_id: 5,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124851,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.550",
      task_id: 51,
      frame_stage_id: 5,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124852,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.560",
      task_id: 51,
      frame_stage_id: 5,
      agent_id: 9,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      task_pers_user: {
        id: 15,
        first_name: "Ralph",
        last_name: "Ward",
        email: "rward@siterra.uk",
        avatar: "",
      },
    },
    {
      id: 124853,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.570",
      task_id: 51,
      frame_stage_id: 5,
      agent_id: 10,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 10,
        agent_name: "Contract Administrator",
      },
      task_pers_user: {
        id: 16,
        first_name: "Robert",
        last_name: "Hunt",
        email: "rhunt@dandf.net",
        avatar: require("@/assets/temp/users/robert_hunt.png"),
      },
    },
    {
      id: 124854,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.580",
      task_id: 51,
      frame_stage_id: 5,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 124855,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.590",
      task_id: 122,
      frame_stage_id: 5,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124856,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.600",
      task_id: 122,
      frame_stage_id: 5,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124857,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.610",
      task_id: 122,
      frame_stage_id: 5,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124858,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.620",
      task_id: 122,
      frame_stage_id: 5,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124859,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.630",
      task_id: 122,
      frame_stage_id: 5,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124860,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.640",
      task_id: 122,
      frame_stage_id: 5,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124861,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.650",
      task_id: 122,
      frame_stage_id: 5,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124862,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.660",
      task_id: 122,
      frame_stage_id: 5,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124863,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.670",
      task_id: 122,
      frame_stage_id: 5,
      agent_id: 9,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      task_pers_user: {
        id: 15,
        first_name: "Ralph",
        last_name: "Ward",
        email: "rward@siterra.uk",
        avatar: "",
      },
    },
    {
      id: 124864,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.680",
      task_id: 122,
      frame_stage_id: 5,
      agent_id: 10,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 10,
        agent_name: "Contract Administrator",
      },
      task_pers_user: {
        id: 16,
        first_name: "Robert",
        last_name: "Hunt",
        email: "rhunt@dandf.net",
        avatar: require("@/assets/temp/users/robert_hunt.png"),
      },
    },
    {
      id: 124865,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.690",
      task_id: 122,
      frame_stage_id: 5,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 124866,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.700",
      task_id: 78,
      frame_stage_id: 5,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 19, 2022",
      task: {
        id: 78,
        task_name: "Liaise with project Lead and Lead Designer",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124867,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.710",
      task_id: 78,
      frame_stage_id: 5,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 19, 2022",
      task: {
        id: 78,
        task_name: "Liaise with project Lead and Lead Designer",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124868,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.720",
      task_id: 78,
      frame_stage_id: 5,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 19, 2022",
      task: {
        id: 78,
        task_name: "Liaise with project Lead and Lead Designer",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124869,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.730",
      task_id: 78,
      frame_stage_id: 5,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 19, 2022",
      task: {
        id: 78,
        task_name: "Liaise with project Lead and Lead Designer",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124870,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.740",
      task_id: 78,
      frame_stage_id: 5,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 19, 2022",
      task: {
        id: 78,
        task_name: "Liaise with project Lead and Lead Designer",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124871,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.750",
      task_id: 78,
      frame_stage_id: 5,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 19, 2022",
      task: {
        id: 78,
        task_name: "Liaise with project Lead and Lead Designer",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124872,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.760",
      task_id: 78,
      frame_stage_id: 5,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 19, 2022",
      task: {
        id: 78,
        task_name: "Liaise with project Lead and Lead Designer",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124873,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.770",
      task_id: 78,
      frame_stage_id: 5,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 19, 2022",
      task: {
        id: 78,
        task_name: "Liaise with project Lead and Lead Designer",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124874,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.780",
      task_id: 78,
      frame_stage_id: 5,
      agent_id: 9,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 19, 2022",
      task: {
        id: 78,
        task_name: "Liaise with project Lead and Lead Designer",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      task_pers_user: {
        id: 15,
        first_name: "Ralph",
        last_name: "Ward",
        email: "rward@siterra.uk",
        avatar: "",
      },
    },
    {
      id: 124875,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.790",
      task_id: 78,
      frame_stage_id: 5,
      agent_id: 10,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 19, 2022",
      task: {
        id: 78,
        task_name: "Liaise with project Lead and Lead Designer",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 10,
        agent_name: "Contract Administrator",
      },
      task_pers_user: {
        id: 16,
        first_name: "Robert",
        last_name: "Hunt",
        email: "rhunt@dandf.net",
        avatar: require("@/assets/temp/users/robert_hunt.png"),
      },
    },
    {
      id: 124876,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.800",
      task_id: 78,
      frame_stage_id: 5,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 19, 2022",
      task: {
        id: 78,
        task_name: "Liaise with project Lead and Lead Designer",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 124877,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.810",
      task_id: 120,
      frame_stage_id: 5,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124878,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.820",
      task_id: 120,
      frame_stage_id: 5,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124879,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.830",
      task_id: 120,
      frame_stage_id: 5,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124880,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.840",
      task_id: 120,
      frame_stage_id: 5,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124881,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.850",
      task_id: 120,
      frame_stage_id: 5,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124882,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.860",
      task_id: 120,
      frame_stage_id: 5,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124883,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.870",
      task_id: 120,
      frame_stage_id: 5,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124884,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.880",
      task_id: 120,
      frame_stage_id: 5,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124885,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.890",
      task_id: 120,
      frame_stage_id: 5,
      agent_id: 9,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      task_pers_user: {
        id: 15,
        first_name: "Ralph",
        last_name: "Ward",
        email: "rward@siterra.uk",
        avatar: "",
      },
    },
    {
      id: 124886,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.900",
      task_id: 120,
      frame_stage_id: 5,
      agent_id: 10,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 10,
        agent_name: "Contract Administrator",
      },
      task_pers_user: {
        id: 16,
        first_name: "Robert",
        last_name: "Hunt",
        email: "rhunt@dandf.net",
        avatar: require("@/assets/temp/users/robert_hunt.png"),
      },
    },
    {
      id: 124887,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.910",
      task_id: 120,
      frame_stage_id: 5,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 124888,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.920",
      task_id: 68,
      frame_stage_id: 5,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 23, 2023",
      task: {
        id: 68,
        task_name: "Exchange technical design",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124889,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "4.930",
      task_id: 156,
      frame_stage_id: 5,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Feb 9, 2021",
      task: {
        id: 156,
        task_name: "Undertake UK government information exchange",
      },
      progress_status: {
        id: 3,
        progress_status_name: "Completed",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124890,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.010",
      task_id: 128,
      frame_stage_id: 6,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 24, 2023",
      task: {
        id: 128,
        task_name:
          "Respond to queries raised by Project Lead or Contract Administrator",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124891,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.020",
      task_id: 84,
      frame_stage_id: 6,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Dec 17, 2023",
      task: {
        id: 84,
        task_name: "Manage implementation of Handover Strategy",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124892,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.030",
      task_id: 121,
      frame_stage_id: 6,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 27, 2022",
      task: {
        id: 121,
        task_name:
          "Provide information as set out in the Design Responsibility Matrix incorporating Information Exchanges in accordance with Design Programme",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124893,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.040",
      task_id: 31,
      frame_stage_id: 6,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jan 13, 2024",
      task: {
        id: 31,
        task_name: "Comment on Construction Programme",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124894,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.050",
      task_id: 89,
      frame_stage_id: 6,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Feb 11, 2022",
      task: {
        id: 89,
        task_name: "Monitor and review progress and performance of design team",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124895,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.060",
      task_id: 10,
      frame_stage_id: 6,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 2, 2024",
      task: {
        id: 10,
        task_name: "Carry out site inspections and review",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124896,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.070",
      task_id: 130,
      frame_stage_id: 6,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 9, 2023",
      task: {
        id: 130,
        task_name:
          "Respond to site queries related to co-ordination or integration",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124897,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.080",
      task_id: 10,
      frame_stage_id: 6,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 2, 2024",
      task: {
        id: 10,
        task_name: "Carry out site inspections and review",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124898,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.090",
      task_id: 129,
      frame_stage_id: 6,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Nov 28, 2023",
      task: {
        id: 129,
        task_name: "Respond to site queries",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124899,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.100",
      task_id: 93,
      frame_stage_id: 6,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 6, 2024",
      task: {
        id: 93,
        task_name: "Prepare 'As Constructed' Information",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124900,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.110",
      task_id: 10,
      frame_stage_id: 6,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 2, 2024",
      task: {
        id: 10,
        task_name: "Carry out site inspections and review",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124901,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.120",
      task_id: 129,
      frame_stage_id: 6,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Nov 28, 2023",
      task: {
        id: 129,
        task_name: "Respond to site queries",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124902,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.130",
      task_id: 93,
      frame_stage_id: 6,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 6, 2024",
      task: {
        id: 93,
        task_name: "Prepare 'As Constructed' Information",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124903,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.140",
      task_id: 10,
      frame_stage_id: 6,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 2, 2024",
      task: {
        id: 10,
        task_name: "Carry out site inspections and review",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124904,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.150",
      task_id: 93,
      frame_stage_id: 6,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 6, 2024",
      task: {
        id: 93,
        task_name: "Prepare 'As Constructed' Information",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124905,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.160",
      task_id: 93,
      frame_stage_id: 6,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 6, 2024",
      task: {
        id: 93,
        task_name: "Prepare 'As Constructed' Information",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124906,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.170",
      task_id: 10,
      frame_stage_id: 6,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 2, 2024",
      task: {
        id: 10,
        task_name: "Carry out site inspections and review",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124907,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.180",
      task_id: 129,
      frame_stage_id: 6,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Nov 28, 2023",
      task: {
        id: 129,
        task_name: "Respond to site queries",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124908,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.190",
      task_id: 93,
      frame_stage_id: 6,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 6, 2024",
      task: {
        id: 93,
        task_name: "Prepare 'As Constructed' Information",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124909,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.200",
      task_id: 118,
      frame_stage_id: 6,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Feb 12, 2024",
      task: {
        id: 118,
        task_name: "Prepare valuations in accordance with Building Contract",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124910,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.210",
      task_id: 158,
      frame_stage_id: 6,
      agent_id: 9,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Nov 9, 2022",
      task: {
        id: 158,
        task_name: "Update Construction Strategy",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      task_pers_user: {
        id: 15,
        first_name: "Ralph",
        last_name: "Ward",
        email: "rward@siterra.uk",
        avatar: "",
      },
    },
    {
      id: 124911,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.220",
      task_id: 2,
      frame_stage_id: 6,
      agent_id: 10,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 7, 2023",
      task: {
        id: 2,
        task_name: "Administer Building Contract",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 10,
        agent_name: "Contract Administrator",
      },
      task_pers_user: {
        id: 16,
        first_name: "Robert",
        last_name: "Hunt",
        email: "rhunt@dandf.net",
        avatar: require("@/assets/temp/users/robert_hunt.png"),
      },
    },
    {
      id: 124912,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.230",
      task_id: 82,
      frame_stage_id: 6,
      agent_id: 10,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 7, 2022",
      task: {
        id: 82,
        task_name: "Manage Change Control process",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 10,
        agent_name: "Contract Administrator",
      },
      task_pers_user: {
        id: 16,
        first_name: "Robert",
        last_name: "Hunt",
        email: "rhunt@dandf.net",
        avatar: require("@/assets/temp/users/robert_hunt.png"),
      },
    },
    {
      id: 124913,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.240",
      task_id: 26,
      frame_stage_id: 6,
      agent_id: 10,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 7, 2024",
      task: {
        id: 26,
        task_name: "Co-ordinate site inspections",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 10,
        agent_name: "Contract Administrator",
      },
      task_pers_user: {
        id: 16,
        first_name: "Robert",
        last_name: "Hunt",
        email: "rhunt@dandf.net",
        avatar: require("@/assets/temp/users/robert_hunt.png"),
      },
    },
    {
      id: 124914,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.250",
      task_id: 26,
      frame_stage_id: 6,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 7, 2024",
      task: {
        id: 26,
        task_name: "Co-ordinate site inspections",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 124915,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.260",
      task_id: 131,
      frame_stage_id: 6,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 28, 2024",
      task: {
        id: 131,
        task_name: "Review 'As Constructed' Information",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 124916,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.270",
      task_id: 14,
      frame_stage_id: 6,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Dec 19, 2023",
      task: {
        id: 14,
        task_name: "Check design sustainability assessment",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124917,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.280",
      task_id: 22,
      frame_stage_id: 6,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "May 2, 2024",
      task: {
        id: 22,
        task_name: "Check sustainability procedures",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124918,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.290",
      task_id: 134,
      frame_stage_id: 6,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jan 27, 2024",
      task: {
        id: 134,
        task_name: "Review handover strategy",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124919,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.300",
      task_id: 13,
      frame_stage_id: 6,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Aug 26, 2023",
      task: {
        id: 13,
        task_name: "Check contractor's interim testing and monitoring",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124920,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.310",
      task_id: 17,
      frame_stage_id: 6,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Feb 2, 2024",
      task: {
        id: 17,
        task_name: "Check non technical user guide",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124921,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.320",
      task_id: 12,
      frame_stage_id: 6,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 20, 2023",
      task: {
        id: 12,
        task_name: "Check as constructed information",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124922,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.330",
      task_id: 51,
      frame_stage_id: 6,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124923,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.340",
      task_id: 51,
      frame_stage_id: 6,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124924,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.350",
      task_id: 51,
      frame_stage_id: 6,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124925,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.360",
      task_id: 51,
      frame_stage_id: 6,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124926,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.370",
      task_id: 51,
      frame_stage_id: 6,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124927,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.380",
      task_id: 51,
      frame_stage_id: 6,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124928,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.390",
      task_id: 51,
      frame_stage_id: 6,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124929,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.400",
      task_id: 51,
      frame_stage_id: 6,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124930,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.410",
      task_id: 51,
      frame_stage_id: 6,
      agent_id: 9,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      task_pers_user: {
        id: 15,
        first_name: "Ralph",
        last_name: "Ward",
        email: "rward@siterra.uk",
        avatar: "",
      },
    },
    {
      id: 124931,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.420",
      task_id: 51,
      frame_stage_id: 6,
      agent_id: 10,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 10,
        agent_name: "Contract Administrator",
      },
      task_pers_user: {
        id: 16,
        first_name: "Robert",
        last_name: "Hunt",
        email: "rhunt@dandf.net",
        avatar: require("@/assets/temp/users/robert_hunt.png"),
      },
    },
    {
      id: 124932,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.430",
      task_id: 51,
      frame_stage_id: 6,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 18, 2022",
      task: {
        id: 51,
        task_name: "Contribute to Health & Safety Strategy",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 124933,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.440",
      task_id: 122,
      frame_stage_id: 6,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124934,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.450",
      task_id: 122,
      frame_stage_id: 6,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124935,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.460",
      task_id: 122,
      frame_stage_id: 6,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124936,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.470",
      task_id: 122,
      frame_stage_id: 6,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124937,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.480",
      task_id: 122,
      frame_stage_id: 6,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124938,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.490",
      task_id: 122,
      frame_stage_id: 6,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124939,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.500",
      task_id: 122,
      frame_stage_id: 6,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124940,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.510",
      task_id: 122,
      frame_stage_id: 6,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124941,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.520",
      task_id: 122,
      frame_stage_id: 6,
      agent_id: 9,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      task_pers_user: {
        id: 15,
        first_name: "Ralph",
        last_name: "Ward",
        email: "rward@siterra.uk",
        avatar: "",
      },
    },
    {
      id: 124942,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.530",
      task_id: 122,
      frame_stage_id: 6,
      agent_id: 10,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 10,
        agent_name: "Contract Administrator",
      },
      task_pers_user: {
        id: 16,
        first_name: "Robert",
        last_name: "Hunt",
        email: "rhunt@dandf.net",
        avatar: require("@/assets/temp/users/robert_hunt.png"),
      },
    },
    {
      id: 124943,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.540",
      task_id: 122,
      frame_stage_id: 6,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Mar 26, 2022",
      task: {
        id: 122,
        task_name:
          "Provide information for and contribute to contents of Project Execution Plan",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 124944,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.550",
      task_id: 77,
      frame_stage_id: 6,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 28, 2024",
      task: {
        id: 77,
        task_name: "Liaise with project Lead",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124945,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.560",
      task_id: 77,
      frame_stage_id: 6,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 28, 2024",
      task: {
        id: 77,
        task_name: "Liaise with project Lead",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124946,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.570",
      task_id: 77,
      frame_stage_id: 6,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 28, 2024",
      task: {
        id: 77,
        task_name: "Liaise with project Lead",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124947,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.580",
      task_id: 77,
      frame_stage_id: 6,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 28, 2024",
      task: {
        id: 77,
        task_name: "Liaise with project Lead",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124948,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.590",
      task_id: 77,
      frame_stage_id: 6,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 28, 2024",
      task: {
        id: 77,
        task_name: "Liaise with project Lead",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124949,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.600",
      task_id: 77,
      frame_stage_id: 6,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 28, 2024",
      task: {
        id: 77,
        task_name: "Liaise with project Lead",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124950,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.610",
      task_id: 77,
      frame_stage_id: 6,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 28, 2024",
      task: {
        id: 77,
        task_name: "Liaise with project Lead",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124951,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.620",
      task_id: 77,
      frame_stage_id: 6,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 28, 2024",
      task: {
        id: 77,
        task_name: "Liaise with project Lead",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124952,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.630",
      task_id: 77,
      frame_stage_id: 6,
      agent_id: 9,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 28, 2024",
      task: {
        id: 77,
        task_name: "Liaise with project Lead",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      task_pers_user: {
        id: 15,
        first_name: "Ralph",
        last_name: "Ward",
        email: "rward@siterra.uk",
        avatar: "",
      },
    },
    {
      id: 124953,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.640",
      task_id: 77,
      frame_stage_id: 6,
      agent_id: 10,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 28, 2024",
      task: {
        id: 77,
        task_name: "Liaise with project Lead",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 10,
        agent_name: "Contract Administrator",
      },
      task_pers_user: {
        id: 16,
        first_name: "Robert",
        last_name: "Hunt",
        email: "rhunt@dandf.net",
        avatar: require("@/assets/temp/users/robert_hunt.png"),
      },
    },
    {
      id: 124954,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.650",
      task_id: 77,
      frame_stage_id: 6,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 28, 2024",
      task: {
        id: 77,
        task_name: "Liaise with project Lead",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 124955,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.660",
      task_id: 120,
      frame_stage_id: 6,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124956,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.670",
      task_id: 120,
      frame_stage_id: 6,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124957,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.680",
      task_id: 120,
      frame_stage_id: 6,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 124958,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.690",
      task_id: 120,
      frame_stage_id: 6,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 124959,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.700",
      task_id: 120,
      frame_stage_id: 6,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124960,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.710",
      task_id: 120,
      frame_stage_id: 6,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124961,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.720",
      task_id: 120,
      frame_stage_id: 6,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124962,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.730",
      task_id: 120,
      frame_stage_id: 6,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124963,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.740",
      task_id: 120,
      frame_stage_id: 6,
      agent_id: 9,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      task_pers_user: {
        id: 15,
        first_name: "Ralph",
        last_name: "Ward",
        email: "rward@siterra.uk",
        avatar: "",
      },
    },
    {
      id: 124964,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.750",
      task_id: 120,
      frame_stage_id: 6,
      agent_id: 10,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 10,
        agent_name: "Contract Administrator",
      },
      task_pers_user: {
        id: 16,
        first_name: "Robert",
        last_name: "Hunt",
        email: "rhunt@dandf.net",
        avatar: require("@/assets/temp/users/robert_hunt.png"),
      },
    },
    {
      id: 124965,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.760",
      task_id: 120,
      frame_stage_id: 6,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 124966,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.770",
      task_id: 64,
      frame_stage_id: 6,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 1, 2023",
      task: {
        id: 64,
        task_name: "Exchange 'As constructed' information",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124967,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "5.780",
      task_id: 156,
      frame_stage_id: 6,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Feb 9, 2021",
      task: {
        id: 156,
        task_name: "Undertake UK government information exchange",
      },
      progress_status: {
        id: 2,
        progress_status_name: "Ongoing",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124968,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.010",
      task_id: 86,
      frame_stage_id: 7,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 86,
        task_name: "Manage tasks listed in Handover Strategy",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124969,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.020",
      task_id: 87,
      frame_stage_id: 7,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 87,
        task_name: "Manage updating of 'As Constructed' Information",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124970,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.030",
      task_id: 89,
      frame_stage_id: 7,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Feb 11, 2022",
      task: {
        id: 89,
        task_name: "Monitor and review progress and performance of design team",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124971,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.040",
      task_id: 153,
      frame_stage_id: 7,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 153,
        task_name: "Undertake tasks listed in Handover Strategy",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124972,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.050",
      task_id: 141,
      frame_stage_id: 7,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 141,
        task_name: "Review updated 'As Constructed' Information",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124973,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.060",
      task_id: 153,
      frame_stage_id: 7,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 153,
        task_name: "Undertake tasks listed in Handover Strategy",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124974,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.070",
      task_id: 157,
      frame_stage_id: 7,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 157,
        task_name: "Update 'As Constructed' Information",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124975,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.080",
      task_id: 153,
      frame_stage_id: 7,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 153,
        task_name: "Undertake tasks listed in Handover Strategy",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124976,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.090",
      task_id: 157,
      frame_stage_id: 7,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 157,
        task_name: "Update 'As Constructed' Information",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124977,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.100",
      task_id: 153,
      frame_stage_id: 7,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 153,
        task_name: "Undertake tasks listed in Handover Strategy",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124978,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.110",
      task_id: 157,
      frame_stage_id: 7,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 157,
        task_name: "Update 'As Constructed' Information",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124979,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.120",
      task_id: 153,
      frame_stage_id: 7,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 153,
        task_name: "Undertake tasks listed in Handover Strategy",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124980,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.130",
      task_id: 157,
      frame_stage_id: 7,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 157,
        task_name: "Update 'As Constructed' Information",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124981,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.140",
      task_id: 153,
      frame_stage_id: 7,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 153,
        task_name: "Undertake tasks listed in Handover Strategy",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124982,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.150",
      task_id: 5,
      frame_stage_id: 7,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 5,
        task_name: "Agree final account",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124983,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.160",
      task_id: 153,
      frame_stage_id: 7,
      agent_id: 9,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 153,
        task_name: "Undertake tasks listed in Handover Strategy",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      task_pers_user: {
        id: 15,
        first_name: "Ralph",
        last_name: "Ward",
        email: "rward@siterra.uk",
        avatar: "",
      },
    },
    {
      id: 124984,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.170",
      task_id: 85,
      frame_stage_id: 7,
      agent_id: 9,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 85,
        task_name:
          "Manage preparation and issue of 'As Constructed' Information by specialist subcontractors",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      task_pers_user: {
        id: 15,
        first_name: "Ralph",
        last_name: "Ward",
        email: "rward@siterra.uk",
        avatar: "",
      },
    },
    {
      id: 124985,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.180",
      task_id: 3,
      frame_stage_id: 7,
      agent_id: 10,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 3,
        task_name: "Advise on the resolution of defects",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 10,
        agent_name: "Contract Administrator",
      },
      task_pers_user: {
        id: 16,
        first_name: "Robert",
        last_name: "Hunt",
        email: "rhunt@dandf.net",
        avatar: require("@/assets/temp/users/robert_hunt.png"),
      },
    },
    {
      id: 124986,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.190",
      task_id: 43,
      frame_stage_id: 7,
      agent_id: 10,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 43,
        task_name: "Conclude administration of Building Contract",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 10,
        agent_name: "Contract Administrator",
      },
      task_pers_user: {
        id: 16,
        first_name: "Robert",
        last_name: "Hunt",
        email: "rhunt@dandf.net",
        avatar: require("@/assets/temp/users/robert_hunt.png"),
      },
    },
    {
      id: 124987,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.200",
      task_id: 137,
      frame_stage_id: 7,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 137,
        task_name: "Review Project Information",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 124988,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.210",
      task_id: 12,
      frame_stage_id: 7,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jul 20, 2023",
      task: {
        id: 12,
        task_name: "Check as constructed information",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124989,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.220",
      task_id: 77,
      frame_stage_id: 7,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 28, 2024",
      task: {
        id: 77,
        task_name: "Liaise with project Lead",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124990,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.230",
      task_id: 77,
      frame_stage_id: 7,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 28, 2024",
      task: {
        id: 77,
        task_name: "Liaise with project Lead",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 124991,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.240",
      task_id: 77,
      frame_stage_id: 7,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 28, 2024",
      task: {
        id: 77,
        task_name: "Liaise with project Lead",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 124992,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.250",
      task_id: 77,
      frame_stage_id: 7,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 28, 2024",
      task: {
        id: 77,
        task_name: "Liaise with project Lead",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 124993,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.260",
      task_id: 77,
      frame_stage_id: 7,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 28, 2024",
      task: {
        id: 77,
        task_name: "Liaise with project Lead",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 124994,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.270",
      task_id: 77,
      frame_stage_id: 7,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 28, 2024",
      task: {
        id: 77,
        task_name: "Liaise with project Lead",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 124995,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.280",
      task_id: 77,
      frame_stage_id: 7,
      agent_id: 9,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 28, 2024",
      task: {
        id: 77,
        task_name: "Liaise with project Lead",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      task_pers_user: {
        id: 15,
        first_name: "Ralph",
        last_name: "Ward",
        email: "rward@siterra.uk",
        avatar: "",
      },
    },
    {
      id: 124996,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.290",
      task_id: 77,
      frame_stage_id: 7,
      agent_id: 10,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 28, 2024",
      task: {
        id: 77,
        task_name: "Liaise with project Lead",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 10,
        agent_name: "Contract Administrator",
      },
      task_pers_user: {
        id: 16,
        first_name: "Robert",
        last_name: "Hunt",
        email: "rhunt@dandf.net",
        avatar: require("@/assets/temp/users/robert_hunt.png"),
      },
    },
    {
      id: 124997,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.300",
      task_id: 77,
      frame_stage_id: 7,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Apr 28, 2024",
      task: {
        id: 77,
        task_name: "Liaise with project Lead",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 124998,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.310",
      task_id: 120,
      frame_stage_id: 7,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 124999,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.320",
      task_id: 120,
      frame_stage_id: 7,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 125000,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.330",
      task_id: 120,
      frame_stage_id: 7,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 125001,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.340",
      task_id: 120,
      frame_stage_id: 7,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 125002,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.350",
      task_id: 120,
      frame_stage_id: 7,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 125003,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.360",
      task_id: 120,
      frame_stage_id: 7,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 125004,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.370",
      task_id: 120,
      frame_stage_id: 7,
      agent_id: 9,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      task_pers_user: {
        id: 15,
        first_name: "Ralph",
        last_name: "Ward",
        email: "rward@siterra.uk",
        avatar: "",
      },
    },
    {
      id: 125005,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.380",
      task_id: 120,
      frame_stage_id: 7,
      agent_id: 10,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 10,
        agent_name: "Contract Administrator",
      },
      task_pers_user: {
        id: 16,
        first_name: "Robert",
        last_name: "Hunt",
        email: "rhunt@dandf.net",
        avatar: require("@/assets/temp/users/robert_hunt.png"),
      },
    },
    {
      id: 125006,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.390",
      task_id: 120,
      frame_stage_id: 7,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Jun 28, 2023",
      task: {
        id: 120,
        task_name: "Provide information",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 125007,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.400",
      task_id: 69,
      frame_stage_id: 7,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 69,
        task_name: "Exchange updated 'As constructed' information",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 125008,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "6.410",
      task_id: 156,
      frame_stage_id: 7,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Feb 9, 2021",
      task: {
        id: 156,
        task_name: "Undertake UK government information exchange",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 125009,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "7.010",
      task_id: 153,
      frame_stage_id: 8,
      agent_id: 1,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 153,
        task_name: "Undertake tasks listed in Handover Strategy",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 1,
        agent_name: "Architect",
      },
      task_pers_user: {
        id: 1,
        first_name: "Sophia",
        last_name: "Holland",
        email: "sholland@imarch.uk",
        avatar: require("@/assets/temp/users/sophia_holland.png"),
      },
    },
    {
      id: 125010,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "7.020",
      task_id: 153,
      frame_stage_id: 8,
      agent_id: 2,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 153,
        task_name: "Undertake tasks listed in Handover Strategy",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 2,
        agent_name: "Building Services Engineer",
      },
      task_pers_user: {
        id: 2,
        first_name: "Travis",
        last_name: "Chambers",
        email: "tchambers@bm.net",
        avatar: require("@/assets/temp/users/travis_chambers.png"),
      },
    },
    {
      id: 125011,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "7.030",
      task_id: 153,
      frame_stage_id: 8,
      agent_id: 3,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 153,
        task_name: "Undertake tasks listed in Handover Strategy",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 3,
        agent_name: "Civil Engineer",
      },
      task_pers_user: {
        id: 5,
        first_name: "Mark",
        last_name: "Green",
        email: "mgreen@brooks.com",
        avatar: require("@/assets/temp/users/mark_green.png"),
      },
    },
    {
      id: 125012,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "7.040",
      task_id: 153,
      frame_stage_id: 8,
      agent_id: 4,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 153,
        task_name: "Undertake tasks listed in Handover Strategy",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 4,
        agent_name: "Client",
      },
      task_pers_user: {
        id: 10,
        first_name: "Mark",
        last_name: "Thompson",
        email: "mthompson@rsp.com",
        avatar: require("@/assets/temp/users/mark_thompson.png"),
      },
    },
    {
      id: 125013,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "7.050",
      task_id: 153,
      frame_stage_id: 8,
      agent_id: 5,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 153,
        task_name: "Undertake tasks listed in Handover Strategy",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 5,
        agent_name: "Cost Consultant",
      },
      task_pers_user: {
        id: 11,
        first_name: "Jessica",
        last_name: "Smith",
        email: "jsmith@zafir.uk",
        avatar: "",
      },
    },
    {
      id: 125014,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "7.060",
      task_id: 153,
      frame_stage_id: 8,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 153,
        task_name: "Undertake tasks listed in Handover Strategy",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 125015,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "7.070",
      task_id: 153,
      frame_stage_id: 8,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 153,
        task_name: "Undertake tasks listed in Handover Strategy",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 125016,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "7.080",
      task_id: 153,
      frame_stage_id: 8,
      agent_id: 8,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 153,
        task_name: "Undertake tasks listed in Handover Strategy",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 8,
        agent_name: "Structural Engineer",
      },
      task_pers_user: {
        id: 14,
        first_name: "Alan",
        last_name: "Davis",
        email: "adavis@tns.uk",
        avatar: "",
      },
    },
    {
      id: 125017,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "7.090",
      task_id: 153,
      frame_stage_id: 8,
      agent_id: 9,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 153,
        task_name: "Undertake tasks listed in Handover Strategy",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 9,
        agent_name: "Construction Lead",
      },
      task_pers_user: {
        id: 15,
        first_name: "Ralph",
        last_name: "Ward",
        email: "rward@siterra.uk",
        avatar: "",
      },
    },
    {
      id: 125018,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "7.100",
      task_id: 153,
      frame_stage_id: 8,
      agent_id: 11,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 153,
        task_name: "Undertake tasks listed in Handover Strategy",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 11,
        agent_name: "Health and Safety Adviser",
      },
      task_pers_user: {
        id: 17,
        first_name: "Seth",
        last_name: "Hopkins",
        email: "shopkins@bsafety.com",
        avatar: "",
      },
    },
    {
      id: 125019,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "7.110",
      task_id: 83,
      frame_stage_id: 8,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 83,
        task_name: "Manage completion of tasks in Handover Strategy",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 125020,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "7.120",
      task_id: 88,
      frame_stage_id: 8,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 88,
        task_name: "Manage updating of Project Information",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 125021,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "7.130",
      task_id: 143,
      frame_stage_id: 8,
      agent_id: 6,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Oct 21, 2021",
      task: {
        id: 143,
        task_name: "Review updated Project Information",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 6,
        agent_name: "Lead Designer",
      },
      task_pers_user: {
        id: 12,
        first_name: "Leighton",
        last_name: "Green",
        email: "lgreen@norcraft.net",
        avatar: "",
      },
    },
    {
      id: 125022,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "7.140",
      task_id: 42,
      frame_stage_id: 8,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 42,
        task_name: "Commissioning",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 125023,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "7.150",
      task_id: 56,
      frame_stage_id: 8,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 56,
        task_name: "Declare carbon performance",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 125024,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "7.160",
      task_id: 69,
      frame_stage_id: 8,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: null,
      task: {
        id: 69,
        task_name: "Exchange updated 'As constructed' information",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
    {
      id: 125025,
      project_id: 446,
      proj_unit_id: 472,
      task_ref: "7.170",
      task_id: 156,
      frame_stage_id: 8,
      agent_id: 7,
      task_pers_name: null,
      agent_pers_id: null,
      user_id: null,
      progress_status_id: 2,
      creator_id: 6,
      task_info_req: null,
      task_deadline: "Feb 9, 2021",
      task: {
        id: 156,
        task_name: "Undertake UK government information exchange",
      },
      progress_status: {
        id: 1,
        progress_status_name: "Upcoming",
      },
      task_pers_agent: {
        id: 7,
        agent_name: "Project Lead",
      },
      task_pers_user: {
        id: 13,
        first_name: "João",
        last_name: "Ribeiro",
        email: "jribeiro@bimms.net",
        avatar: "",
      },
    },
  ];
};
